import p1 from './../assets/images/products/1.jpeg';
import p2 from './../assets/images/products/2.jpg';
import p3 from './../assets/images/products/3.jpg';

import img1 from './../assets/images/products/1.png';
import img2 from './../assets/images/products/2.png';
import img2000k from './../assets/images/products/2000k.png';
import img2000b from './../assets/images/products/2000b.png';
import imgRaz from './../assets/images/products/raz.png';
import spray from './../assets/images/products/sprej.png';


export const products = [{
    id: 1,
    title: 'MAZIVA ZA ŽELJEZNIČKU INDUSTRIJU',
    short: 'Podmazivanje prirubnice tračnica i kotača',
    description: [
      'Naša sredstva za podmazivanje tračnica i prirubnica kotača pružaju dugotrajnu zaštitu od habanja i stvaranja pukotina. Podmazuju i kondicioniraju, sprječavaju škripanje i rade čak i pod ekstremnim tlačnim i temperaturnim opterećenjima.',
      'CICO® proizvodi su ekonomični za korištenje, iznimno učinkoviti i održivi te biorazgradivi. Podnose širok raspon uvjeta okoline, mogu se koristiti u različitim sustavima vozila i tračnica te se mogu primijeniti različitim metodama podmazivanja i tehnologijom sustava podmazivanja.',
      'CICO® proizvodi : dugoročno učinkoviti, pouzdani i isplativi. Nude veću sigurnost, manju buku i zagađenje okoliša te značajno povećanje učinkovitosti.'
    ],
    products: [
      {
        id: 0,
        name: 'CICO® TL 22 B',
        short: 'mazivo za prirubnice kotača',
        link: '',
        bulletpoints: [
          'smanjuje trošenje rubova kotača i rubova tračnica', 
          'bez pukotina ili metalnih krhotina',
          'visoka površinska adhezija, visoka tlačna čvrstoća',
          'nema bacanja',
          'bez gumiranja',
          'značajno dulji intervali održavanja',
          'primjenjiv do -40°C',
          'mala potrošnja',
          'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® TL 22 B , mazivo za prirubnice kotača , kombinacija je kemijski tretiranih krutih maziva i biorazgradivog organskog nosača bez otapala. Posebno je razvijen za upotrebu u posebnim sustavima za prskanje prirubnice kotača kako bi se dugoročno učinkovito smanjilo trenje pri kotrljanju i klizanju između prirubnice kotača i bokova tračnice te da bi se značajno smanjilo trošenje i buka.', 
          'CICO® TL 22 B karakterizira dobra stabilnost s visokim udjelom krutih tvari. Također podnosi ekstremna opterećenja okoline i tlaka i može se koristiti do -40°C. Zbog visoke razine prianjanja na metalnu površinu, gotovo je nemoguće da aktivna tvar bude izbačena iz prirubnice kotača. Ne dolazi do stvaranja desni. CICO® TL 22 B uklanja postojeće male pukotine, sprječava daljnje stvaranje pukotina i lomljenje metala na prirubnici kotača i bokovima tračnica, smanjuje otpor kotrljanja i trošenje. Čak iu malim dozama stvara mazivi film koji izuzetno čvrsto prianja na metalne površine i stabilan je tijekom dugog vremenskog perioda te jamči konstantno podmazivanje. Učinak kočenja povećava CICO® TL 22 Bne utječe i razina buke je smanjena.',
          'CICO® TL 22 B doprinosi sigurnosti i stabilnosti vozila, kao i dugoročno učinkovitom smanjenju troškova i učinkovitosti.',
          'CICO® TL 22 B je idealno mazivo za sustave za prskanje prirubnica kotača koji mogu isporučiti maziva za prirubnice kotača koja sadrže visoku količinu krutine. Uz odgovarajuće sustave prskanja, proizvod je također prikladan za vrste zupčanika kao što su prstenasti zupčanici.',
          'CICO® TL 22 B se isporučuje u pakiranjima od 5 kg, 10 kg, 25 kg i 200 kg. Ostale veličine spremnika na upit.',
        ],
        table: [
          [
            'NAZIV',
            'CICO® TL 22 B',
          ],
          [
            'Boja',
            'Sivo',
          ],
          [
            'Gustoća (na 20°C)',
            '1,03 g/ cm3',
          ],
          [
            'Viskoznost (Brookfield LVT; 5,0 o/min, vreteno #1, 24°C)',
            '1150 mPa.s',
          ],
          [
            'NLGI klasa (DIN 51818)',
            '000',
          ],
          [
            'temperatura primjene',
            '-40°C do +90°C',
          ],
          [
            'područje podmazivanja',
            '-100°C do +500°C',
          ],
          [
            'plamište',
            '255°C',
          ],
          [
            'temperatura paljenja',
            '>350°C',
          ],
          [
            'termalno raspadanje',
            '>350°C',
          ],
          [
            'Opasne reakcije',
            'Ne',
          ],
          [
            'Biorazgradivost (CEC-L-33-A-94)',
            '>90% (bazno ulje)',
          ],
          [
            'Klasa opasnosti od vode (WGK)',
            '1',
          ],
          [
            'Odlaganje (AVV)',
            '130207',
          ],
          [
            'prijevoz',
            'Bez opasnih tvari',
          ],
          [
            'stabilnost skladištenja',
            'najmanje 2 godine',
          ],
        ],
        image: img1,
      },
      {
        id: 1,
        name: 'CICO® TL 1500 B',
        link: '',
        short: 'mazivo za prirubnice kotača',
        bulletpoints: [
          'Smanjenje trošenja i buke', 
          'kontrolirana tiksotropna svojstva',
          'visoka tlačna čvrstoća',
          'ne veže prašinu',
          'visoka površinska adhezija',
          'bez gumiranja',
          'Otpornost na temperaturu mazivog filma od -100°C do +500°C',
          'primjenjiv do -40°C',
          'mala potrošnja',
          'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® TL 1500 B , mazivo za prirubnice kotača , kombinacija je visoko postotnih kemijski tretiranih krutih maziva i biorazgradivog organskog nosača bez otapala. Razvijen je za smanjenje dugotrajnog trenja pri kotrljanju i klizanju između prirubnice kotača i bokova tračnice do kojeg dolazi pod ekstremnim opterećenjima okoline i tlaka pri upravljanju željezničkim vozilima te za značajno smanjenje trošenja. ', 
          'CICO® TL 1500 B uklanja male pukotine. Kao rezultat toga, više se ne mogu vidjeti pukotine i izbijanje metala na prirubnici kotača i bokovima tračnice. Snažno prijanjajući film za odvajanje karakterizira dobra stabilnost s visokim udjelom krutih tvari. Osigurava suhu, homogenu površinsku oblogu uz vrlo malu potrošnju. Odbacivanje ili oštećenje okolnih komponenti gotovo je nemoguće. ',
          'CICO® TL 1500 B smanjuje otpor kotrljanja i trošenje. CICO® TL 1500 B ne smanjuje učinak kočenja i smanjuje razinu buke. Zajamčena je visoka razina operativne pouzdanosti.',
          'CICO® TL 1500 B doprinosi sigurnosti i stabilnosti vozila, kao i dugoročno učinkovitom smanjenju troškova i učinkovitosti.',
          'U kombinaciji s odgovarajućim načinom nanošenja, CICO® TL 1500 B stvara mazivi film koji se ne može otopiti uobičajenim sredstvima za čišćenje i koji izuzetno čvrsto prianja na metalne površine, čak i pri malim dozama. Izbjegava se veza s abrazijom tračnica i cestovnom prljavštinom. Dugotrajni film za razdvajanje i podmazivanje sprječava neispravno zaleđivanje tijekom zimskog razdoblja. Trošenje i buka značajno su smanjeni.',
          'CICO® TL 1500 B se isporučuje u pakiranjima od 5 kg, 10 kg, 25 kg i 200 kg. Ostale veličine spremnika na upit.',
        ],
        table: [
          [
            'NAZIV',
            'CICO® TL 1500 B',
          ],
          [
            'Boja',
            'Sivo',
          ],
          [
            'Gustoća (na 20°C)',
            '1,03 g/ cm3',
          ],
          [
            'Viskoznost (Brookfield LVT; 5,0 o/min, vreteno #1, 24°C)',
            '1150 mPa.s',
          ],
          [
            'Kinematička viskoznost (ISO 3104; na 24°C)',
            '324 mm2 / s',
          ],
          [
            'NLGI klasa (DIN 51818)',
            '000',
          ],
          [
            'temperatura primjene',
            '-40°C do +90°C',
          ],
          [
            'područje podmazivanja',
            '-100°C do +500°C',
          ],
          [
            'plamište',
            '255°C',
          ],
          [
            'temperatura paljenja',
            '>350°C',
          ],
          [
            'termalno raspadanje',
            '>350°C',
          ],
          [
            'Opasne reakcije',
            'Ne',
          ],
          [
            'Biorazgradivost (CEC-L-33-A-94)',
            '>90% (bazno ulje)',
          ],
          [
            'Klasa opasnosti od vode (WGK)',
            '1',
          ],
          [
            'Odlaganje (AVV)',
            '130207',
          ],
          [
            'prijevoz',
            'Bez opasnih tvari',
          ],
          [
            'stabilnost skladištenja',
            'najmanje 2 godine',
          ],
        ],
        image: img1,
      },
      {
        id: 2,
        name: 'CICO® TL 1600 B',
        short: 'mazivo za kotače i zupčanike',
        link: '',
        bulletpoints: [
          'Smanjenje trošenja i buke na prirubnici kotača i lančaniku', 
          'Sprječava stvaranje pukotina i erupciju metala (npr. lomove vijenca zupčanika)',
          'visoka stabilnost',
          'Otpornost na temperaturu mazivog filma od -90°C do +550°C',
          'upotrebljiv do -40°C',
          'visoka tlačna čvrstoća i visoka površinska adhezija',
          'nema bacanja',
          'nema gumiranja, ne veže prljavštinu',
          'mala potrošnja',
          'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® TL 1600 B , mazivo za kotače i zupčanike , kombinacija je kemijski tretiranih krutih maziva i biorazgradivog organskog nosača bez otapala. Posebno je razvijen za prstenaste zupčanike i druge otvorene sustave zupčanika kako bi se smanjilo trenje kotrljanja i klizanja koje se dugoročno javlja pod ekstremnim tlačnim opterećenjima i značajno smanjilo trošenje. Učinkovito je spriječeno pucanje i izbijanje metala na rubu zupčanika. Čak i pri niskim dozama, CICO® TL 1600 B stvara mazivi film koji se ne može ukloniti uobičajenim sredstvima za čišćenje i izuzetno čvrsto prianja na metalne površine.', 
          'Čvrsto prianjajući film za razdvajanje i podmazivanje osigurava suhu, homogenu površinsku prevlaku uz samo malu potrošnju. Također je otporan na ekstremna tlačna i temperaturna opterećenja tijekom dugog vremenskog razdoblja. Odbacivanje ili oštećenje okolnih komponenti gotovo je nemoguće. To jamči stalno podmazivanje i značajno smanjenje trošenja, kao i visoku radnu pouzdanost.',
          'CICO® TL 1600 B doprinosi sigurnosti i stabilnosti vozila, kao i dugoročno učinkovitom smanjenju troškova i učinkovitosti.',
          'CICO® TL 1600 B može se koristiti na temperaturama do -40 °C pomoću odgovarajućeg sustava crpke. Otpornost na temperaturu dugotrajnog filma za razdvajanje i podmazivanje zajamčena je s dosljednom funkcijom od -90°C do +550°C.',
          'CICO® TL 1600 B se isporučuje u spremnicima od 10 kg, 25 kg i 200 kg. Ostale veličine spremnika na upit.',
        ],
        table: [
          [
            'NAZIV',
            'CICO® TL 1600 B',
          ],
          [
            'Boja',
            'Sivo',
          ],
          [
            'Gustoća (na 20°C)',
            '1,03 g/ cm3',
          ],
          [
            'Viskoznost (Brookfield LVT; 5,0 o/min, vreteno #1, 24°C)',
            '1150 mPa.s',
          ],
          [
            'Kinematička viskoznost (ISO 3104; na 24°C)',
            '324 mm2 / s',
          ],
          [
            'NLGI klasa (DIN 51818)',
            '000',
          ],
          [
            'temperatura primjene',
            '-40°C do +90°C',
          ],
          [
            'područje podmazivanja',
            '-100°C do +500°C',
          ],
          [
            'plamište',
            '255°C',
          ],
          [
            'temperatura paljenja',
            '>350°C',
          ],
          [
            'termalno raspadanje',
            '>350°C',
          ],
          [
            'Opasne reakcije',
            'Ne',
          ],
          [
            'Biorazgradivost (CEC-L-33-A-94)',
            '>90% (bazno ulje)',
          ],
          [
            'Klasa opasnosti od vode (WGK)',
            '1',
          ],
          [
            'Odlaganje (AVV)',
            '130207',
          ],
          [
            'prijevoz',
            'Bez opasnih tvari',
          ],
          [
            'stabilnost skladištenja',
            'najmanje 2 godine',
          ],
        ],
        image: img1,
      },
      {
        id: 3,
        name: 'CICO® RS 17 B',
        short: 'sredstvo za odvajanje kotača i tračnica',
        link: '',
        bulletpoints: [
          'nema škripe kuta',
          'Smanjeno trošenje kotača i prirubnice kotača',
          'nema pucanja i izbijanja metala',
          'dobra stabilnost, visoka tlačna čvrstoća',
          'visoka površinska adhezija, bez odbacivanja',
          'nema gumiranja, ne veže prljavštinu',
          'mala potrošnja',
          'primjenjiv do -40°C',
          'Otporan na temperaturu -100 do +500°C',
          'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® RS 17 B , sredstvo za odvajanje kotača i tračnica , je tiksotropni proizvod koji se uspješno koristi u sektoru tračničkih vozila za smanjenje trošenja i buke, posebno protiv škripe u zavojima. Razvijen je za upotrebu u posebnim uređajima za podmazivanje za podmazivanje kotača i prirubnice kotača.', 
          'CICO® RS 17 B karakterizira visok sadržaj krutih tvari i dobra stabilnost. Čvrsto prianjajući film za razdvajanje osigurava suh i homogen površinski premaz čak i kada se koristi u malim količinama. Također je učinkovit pod ekstremnim okolišnim i tlačnim opterećenjima.',
          'Male postojeće pukotine i metalni čipovi na površinama kotača i tračnice su eliminirani i stvorene su ujednačene površine. Otpor kotrljanja i trošenje su smanjeni, a stabilnost vozila i upravljanje zavojima su poboljšani. Negativni učinci na učinak kočenja nisu vidljivi s odgovarajućom dozom. Time se povećava sigurnost i učinkovitost rada vozila, a istovremeno značajno smanjuje zagađenje bukom. CICO® RS 17 B može se koristiti s odgovarajućim sustavima prskanja na temperaturama do -40°C. Učinkovitost filma za odvajanje ostaje u temperaturnom rasponu od -100° do +500°C. Izbjegava se veza s abrazijom tračnica i cestovnom prljavštinom.',
          'CICO® RS 17 B nanosi se na kotače ili glavu tračnice pomoću odgovarajućeg sustava raspršivanja. Ovi sustavi mogu se ugraditi u željeznička vozila i na posebna vozila za održavanje tračnica. Može se nanositi i ručno.',
          'Budući da utjecaj na ponašanje pri kočenju uvelike ovisi o vrsti i količini doziranja, preporučujemo da napravite vlastite testove u realnim uvjetima. Za to nudimo iskusnu podršku.',
          'CICO® RS 17 B se isporučuje u spremnicima od 10 kg, 25 kg i 200 kg. Ostale veličine spremnika na upit.',
        ],
        table: [
          [
            'NAZIV',
            'CICO® RS 17 B',
          ],
          [
            'Boja',
            'Sivo',
          ],
          [
            'Gustoća (na 20°C)',
            '1,03 g/ cm3',
          ],
          [
            'Viskoznost (Brookfield LVT; 5,0 o/min, vreteno #1, 24°C)',
            '1120 mPa.s',
          ],
          [
            'Kinematička viskoznost (ISO 3104; na 24°C)',
            '289 mm2 / s',
          ],
          [
            'temperatura primjene',
            '-40°C do +90°C',
          ],
          [
            'područje podmazivanja',
            '-100°C do +500°C',
          ],
          [
            'plamište',
            '255°C',
          ],
          [
            'temperatura paljenja',
            '>350°C',
          ],
          [
            'termalno raspadanje',
            '>350°C',
          ],
          [
            'Opasne reakcije',
            'Ne',
          ],
          [
            'Biorazgradivost (CEC-L-33-A-94)',
            '>90% (bazno ulje)',
          ],
          [
            'Klasa opasnosti od vode (WGK)',
            '1',
          ],
          [
            'Odlaganje (AVV)',
            '130207',
          ],
          [
            'prijevoz',
            'Bez opasnih tvari',
          ],
          [
            'stabilnost skladištenja',
            'najmanje 2 godine',
          ],
        ],
        image: img1,
      },
      {
        id: 4,
        name: 'CICO® RS 20 B',
        link: '',
        short: 'sredstvo za odvajanje kotača i tračnica',
        bulletpoints: [
          'nema škripe kuta',
          'Smanjeno trošenje kotača i prirubnice kotača',
          'sprječava pucanje i lomljenje metala',
          'dobra stabilnost, visoka tlačna čvrstoća',
          'visoka površinska adhezija, bez odbacivanja',
          'nema gumiranja, ne veže prljavštinu',
          'mala potrošnja',
          'primjenjiv do -40°C',
          'Otporan na temperaturu od -100°C do +500°C',
          'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® RS 20 B ,  sredstvo za odvajanje kotača i tračnica , je tiksotropni proizvod koji se uspješno koristi u sektoru tračničkih vozila za smanjenje trošenja i buke, posebno protiv škripe u zavojima. Razvijen je za upotrebu u posebnim uređajima za podmazivanje za podmazivanje kotača i prirubnice kotača.', 
          'CICO® RS 20 B karakterizira visok sadržaj krutih tvari i dobra stabilnost. Čak i uz malu potrošnju, dobro prianjajući film za razdvajanje osigurava suh i homogen površinski premaz. Sredstvo za odvajanje također je učinkovito pod ekstremnim okolišnim i tlačnim opterećenjima. Male postojeće pukotine i metalni čipovi na površinama kotača i tračnice su eliminirani i stvorene su ujednačene površine. Smanjeni su otpor kotrljanja i trošenje, a poboljšana je stabilnost vozila i ponašanje na limovima.',
          'CICO® RS 20 B može se koristiti na temperaturama do -40°C. Film za odvajanje ostaje učinkovit u rasponu temperatura od -100°C do +500°C. Izbjegava se veza s abrazijom tračnica i cestovnom prljavštinom. Gotovo je nemoguće da okolne komponente budu odbačene ili nepovoljno pogođene, trošenje materijala i napor čišćenja značajno su smanjeni. Negativni učinci na učinak kočenja nisu vidljivi s odgovarajućom dozom. Korištenje CICO® RS 20 B povećava sigurnost i učinkovitost rada vozila. Škripanje na zavojima je eliminirano, a razina buke značajno smanjena.',
          'CICO® RS 20 B može se nanijeti na kotače ili glavu tračnice pomoću odgovarajućeg sustava za prskanje. Ovi sustavi mogu se ugraditi u tračnička vozila kao i na posebna vozila za održavanje tračnica. Može se nanositi i ručno.',
          'Budući da utjecaj na ponašanje pri kočenju uvelike ovisi o vrsti i količini doziranja, preporučujemo provođenje vlastitih testova u realnim uvjetima. Za to nudimo iskusnu podršku.',
          'CICO® RS 20 B se isporučuje u spremnicima od 10 kg, 25 kg i 200 kg. Ostale veličine spremnika na upit.',
        ],
        table: [
          [
            'NAZIV',
            'CICO® RS 20 B',
          ],
          [
            'Boja',
            'Sivo',
          ],
          [
            'Gustoća (na 20°C)',
            '1,03 g/ cm3',
          ],
          [
            'Viskoznost (Brookfield LVT; 5,0 o/min, vreteno #1, 24°C)',
            '1150 mPa.s',
          ],
          [
            'Kinematička viskoznost (ISO 3104; na 24°C)',
            '317 mm2 / s',
          ],
          [
            'temperatura primjene',
            '-40°C do +90°C',
          ],
          [
            'područje podmazivanja',
            '-100°C do +500°C',
          ],
          [
            'plamište',
            '255°C',
          ],
          [
            'temperatura paljenja',
            '>350°C',
          ],
          [
            'termalno raspadanje',
            '>350°C',
          ],
          [
            'Opasne reakcije',
            'Ne',
          ],
          [
            'Biorazgradivost (CEC-L-33-A-94)',
            '>90% (bazno ulje)',
          ],
          [
            'Klasa opasnosti od vode (WGK)',
            '1',
          ],
          [
            'Odlaganje (AVV)',
            '130207',
          ],
          [
            'prijevoz',
            'Bez opasnih tvari',
          ],
          [
            'stabilnost skladištenja',
            'najmanje 2 godine',
          ],
        ],
        image: img1,
      },
      {
        id: 5,
        name: 'CICO® SM 1200 MB',
        link: '',
        short: 'sredstvo za tretiranje glave tračnica',
        bulletpoints: [
          'eliminira škripu u zavojima',
          'sprječava pucanje i lomljenje metala',
          'primjenjiv do -40°C',
          'otporan na visoke temperature',
          'otporan na vodu, slanu vodu i kemikalije',
          'ekološki prihvatljiv, bez opasnih tvari',
          'Nanošenje ručno ili odgovarajućim sustavom prskanja',
        ],
        description: [
          'CICO® SM 1200 MB , sredstvo za tretiranje glave tračnica , čvrsto je sredstvo za tretiranje posebno formulirano na metalnoj osnovi za sprječavanje škripanja u zavojima u željezničkim vozilima.', 
          'CICO® SM 1200 MB odmah i uspješno otklanja neugodno škripanje na zavojima. Zahvaljujući izvanrednom prianjanju, čak i nanošenje tankog filma aktivne tvari na glavu tračnice nudi dugotrajnu zaštitu i smanjenje abrazije tračnice na kritičnim točkama. Zbog prirode proizvoda i načina primjene nemoguć je spoj sa željezničkom i cestovnom prljavštinom. Kondicioniranje suhe površine postiže se CICO® SM 1200 MB .',
          'CICO® SM 1200 MB također prianja na mokre tračnice. Aktivna tvar ravnomjerno se raspoređuje sljedećim kotačima. To stvara tanku površinsku prevlaku koju je potrebno samo obnavljati u skladu sa stupnjem opterećenja u zavojima i vremenskim utjecajima. Pogoršanje učinka kočenja ili usporavanje kočenja jedva se ili uopće ne može uočiti.',
          'Kod ručnog nanošenja CICO® SM 1200 MB četkom, sredstvo se nanosi što je moguće tanje u području krivine: počevši od početka krivine na obje glave tračnica u trakama duljine 10 cm i razmaka 5 m.',
          'CICO® SM 1200 MB također se može nanijeti na glavu tračnice pomoću odgovarajućeg visokotlačnog sustava za prskanje. Takvi sustavi mogu se ugraditi u željeznička vozila ili na posebna vozila za održavanje tračnica.',
          'CICO® SM 1200 MB je ekološki prihvatljiv i bezopasan. Nosač je biorazgradiv.',
          'CICO® SM 1200 MB isporučuje se u spremnicima od 1 kg, 5 kg i 10 kg. Ostale veličine spremnika na upit.',
        ],
        table: [
          [
            'NAZIV',
            'CICO® SM 1200MB',
          ],
          [
            'Boja i oblik',
            'Siva, pasta',
          ],
          [
            'područje vrenja',
            '290-340°C',
          ],
          [
            'Gustoća (na 20°C)',
            '1,2 g/ cm3',
          ],
          [
            'Viskoznost (na 40°C)',
            '45 mm 2 /s (bazno ulje)',
          ],
          [
            'NLGI klasa',
            '2',
          ],
          [
            'topljivost u vodi',
            '0,2 g/l',
          ],
          [
            'temperatura paljenja',
            '>450°C',
          ],
          [
            'termalno raspadanje',
            '>350°C',
          ],
          [
            'Opasne reakcije',
            'Ne',
          ],
          [
            'Biorazgradivost (CEC-L-33-A-94)',
            '>90% (bazno ulje)',
          ],
          [
            'Klasa opasnosti od vode (WGK)',
            '1',
          ],
          [
            'Odlaganje (AVV)',
            '130207',
          ],
          [
            'prijevoz',
            'Bez opasnih tvari',
          ],
          [
            'stabilnost skladištenja',
            'najmanje 2 godine',
          ],
        ],
        image: img1,
      },
      {
        id: 6,
        name: 'CICO® WSM 3000 T',
        link: '',
        short: 'mazivo za točke',
        bulletpoints: [
          'primjenjiv do -50°C',
          'učinkovit čak i pri ekstremno visokim temperaturama',
          'sprječava zaleđivanje',
          'ne veže prljavštinu',
          '(morski) vodootporan',
          'mala potrošnja',
          'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® WSM 3000 T , mazivo za prekidače , kombinacija je kemijski tretiranih krutih maziva i biorazgradivog, organskog nosača. Razvijen je kako bi osigurao besprijekoran rad točaka čak i pod ekstremnim vremenskim uvjetima.', 
          'CICO® WSM 3000 T stvara izuzetno ljepljiv mazivi film čak i pri malim dozama. Također je otporan na ekstremne temperature tijekom dugog vremenskog razdoblja i sprječava neispravno zaleđivanje tijekom zimskog razdoblja. Čak i pri ekstremno visokim temperaturama ljeti, konstantno podmazivanje je zajamčeno. Dugotrajni film za razdvajanje i podmazivanje ne veže prašinu, metalne ostatke ili druga onečišćenja. CICO® WSM 3000 T je niske potrošnje.',
          'Korištenje CICO® WSM 3000 T rezultira značajno povećanom učinkovitosti rada vlaka kroz poboljšanu funkcionalnost i sigurnost skretnica uz značajno smanjene troškove održavanja.',
          'CICO® WSM 3000 T treba primijeniti na prekidače koji su što čistiji. Može se nanositi četkama, špricama, pumpicama i svim sustavima za raspršivanje prikladnim za čvrsta maziva.',
          'CICO® WSM 3000 T isporučuje se u spremnicima od 5 kg, 10 kg, 25 kg i 200 kg. Ostale veličine spremnika na upit.',
        ],
        table: [
          ['NAZIV', '	CICO® WSM 3000 T'],
          ['boja',	'Sivo'],
          ['Gustoća (ISO 12185; na 20°C)', '1,01 g/cm3'],
          ['Kinematička viskoznost (ISO 3104)	na 25°C', '0,192 Pas'],
          ['Kinematička viskoznost (ISO 3104)	na -35°C', '9.10 Pas'],
          ['plamište',	'250°C'],
          ['temperatura paljenja	', '>400°C'],
          ['točka tečenja	', '-55°C'],
          ['termalno raspadanje	', '>350°C'],
          ['otpornost na temperaturu	', '-100°C do +600°C'],
          ['Opasne reakcije',	'Ne'],
          ['Biorazgradivost (CEC-L-33-A-94)	', '>90% (bazno ulje)'],
          ['Klasa opasnosti od vode (WGK)',	'1'],
          ['Odlaganje (AVV)',	'130207'],
          ['prijevoz	Bez opasnih', 'tvari'],
          ['stabilnost skladištenja	', 'najmanje 2 godine'],
        ],
        image: img1,
      },
    ],
    productImage: p1
  },
  {
    id: 2,
    title: 'SPECIJALNA MAZIVA ZA INDUSTRIJU',
    short: 'Maziva i konzervansi, paste za otpuštanje',
    description: [
      'CICO® paste, sredstva za klizanje, maziva i konzervansi jamče dugotrajnu zaštitu od korozije, zapinjanja i struganja - čak i pod najtežim uvjetima industrijske montaže i održavanja. Mogu se koristiti u širokom rasponu temperatura, otporni su na kemikalije i slanu vodu te pokazuju optimalna svojstva otpuštanja i podmazivanja čak i pri visokom tlaku.', 
      'CICO® paste, sredstva za klizanje, maziva i konzervansi smanjuju otpor trenja, starenje i trošenje. Brzo i učinkovito podmazuju, odvajaju i čuvaju. CICO® proizvodi su ekonomični za korištenje te imaju dugotrajan i ekološki prihvatljiv učinak. Osim toga, smanjeni su trud i troškovi održavanja.',
      'Za poseban tretman transportnih i željezničkih sustava u brodogradilištima, na građevinskim i industrijskim lokacijama, nudimo visoko učinkovita i ekološki prihvatljiva maziva koja su izuzetno laka za korištenje (čak i na kratkim udaljenostima).',
    ],
    products: [
      {
        id: 7,
        name: 'CICO® SM 1200 B',
        bulletpoints: [
          'vrlo učinkovita servisna i montažna pasta',
          'optimalno podmazivanje',
          'za statične i pokretne komponente',
          'Dugotrajna zaštita od habanja i korozije',
          'stvara optimalni otpor trenja',
          'rezultira ravnomjernijim predopterećenjem',
          'otporan na pritisak do 7,5 t/cm 2',
          'Otporan na temperaturu -180°C do +1200°C',
          'primjenjiv do -40°C',
          'otporan na slanu vodu i kemikalije',
          'ekološki prihvatljiva osnovna tekućina, netoksična',
        ],
        description: [
          'CICO® SM 1200 B , univerzalna servisna i montažna pasta , kombinacija je kemijski tretiranih krutih maziva i biorazgradivog organskog nosača bez otapala. Čak i tanki površinski film optimalno podmazuje i odvaja. Stvara visoku dugotrajnu zaštitu od zapinjanja i korozije. Neobično visoka tlačna čvrstoća i optimalna otpornost na trenje osiguravaju ravnomjernije predopterećenje tijekom montaže. Demontaža je lakša i nakon godina.', 
          'CICO® SM 1200 B štiti od korozije i habanja čak i pri visokim temperaturama do +1200°C iu korozivnoj atmosferi. Koristi se u svim područjima mehanike: u kliznim ležajevima, vijčanim i fiksnim spojevima, vodilicama, vijcima, klinovima, čahurama i šarkama, na spojevima, zglobovima, zupčanicima i žičanim užadima, između ostalog',
          'CICO® SM 1200 B se može jednostavno nanijeti kao pasta pomoću lopatice ili kista ili, ako je potrebno, razrijediti s CICO® Thinner K. CICO® SM 1200 B također je dostupan kao sprej za upotrebu u teško dostupnim područjima . Sprej treba protresti prije upotrebe zbog krutih lubrikanata koji sadrži.  CICO® SM 1200 B se isporučuje u sljedećim veličinama spremnika: 1 kg, 5 kg, 10 kg i CICO® SM 1200 B sprej (400 ml). Ostale veličine spremnika na upit.',
          'Sprej je pod pritiskom. Ne smije doći u ruke djece i mora biti zaštićen od zagrijavanja i oštećenja. Nemojte prskati na otvoreni plamen ili bilo koji užareni predmet. Koristite samo na otvorenom ili u dobro prozračenom prostoru. Izbjegavajte udisanje aerosola i zaštitite oči. Odložiti samo kada je potpuno prazan.',
        ],
        table: [
          ['NAZIV',	'CICO® SM 1200 B'],
          ['boja',	'Sivo'],
          ['Gustoća (na 20°C)',	'1,2 g/ cm3'],
          ['NLGI klasa',	'2 (tiksotropni)'],
          ['Viskoznost (na 40°C)',	'120 mm 2 /s (bazno ulje)'],
          ['otpornost na pritisak',	'7,5 t/cm 2 (Bruggerova metoda)'],
          ['otpornost na temperaturu',	'-180°C do 1200°C'],
          ['Topivost u vodi (na 24°C)',	'0,2 g/l'],
          ['plamište',	'>300ºC'],
          ['temperatura paljenja',	'>350°C'],
          ['termalno raspadanje',	'>370°C'],
          ['Opasne reakcije',	'Ne'],
          ['Biorazgradivost (CEC-L-33-A-94)',	'>90% (bazno ulje)'],
          ['Klasa opasnosti od vode (WGK)',	'1'],
          ['Odlaganje (AVV)',	'130207'],
          ['prijevoz',	'bez opasnih tvari'],
          ['stabilnost skladištenja',	'najmanje 2 godine'],
          ['NAZIV',	'CICO® SM 1200 B sprej'],
          ['oblik',	'Aerosol, djelatna tvar: tekućina'],
          ['boja',	'Sivo'],
          ['Miris',	'karakteristika'],
          ['Gustoća (na 20°C)','	0,66 g/cm 3 , djelatna tvar: 0,88 g/ml'],
          ['zapaljivo',	'ne odnosi se na aerosole'],
          ['Sadržaj otapala, VOC (EU)',	'80%'],
          ['temperatura samozapaljenja',	'ne odnosi se na aerosole'],
          ['Eksplozivna svojstva',	'nije eksplozivan, moguće je stvaranje eksplozivne smjese para/zrak'],
          ['Opasne reakcije',	'Ne'],
          ['topljivosti',	'Nema informacija'],
          ['Odlaganje (AVV)',	'150104 (metalna ambalaža) 150110 (može sadržavati ostatke opasnih tvari)'],
          ['prijevoz',	'UN1950 (Aerosoli)'],
          ['stabilnost skladištenja',	'najmanje 2 godine'],
        ],
        image: img2,
        link: '',
        short: 'univerzalna servisna i montažna pasta - pasta i sprej',
      },
      {
        id: 8,
        name: 'CICO® SM 1600 B',
        link: '',
        short: 'univerzalna servisna i montažna pasta | bez bakra',
        bulletpoints: [
          'Visoko učinkovita servisna i montažna pasta, bez bakra',
          'pruža dugotrajnu zaštitu od habanja i korozije',
          'otporan na visoke temperature do +1600°C',
          'otporan na visoki pritisak do 7,5 t/cm 2',
          'otporan na slanu vodu i kemikalije',
          'nudi optimalnu otpornost na trenje',
          'proizvodi ravnomjernije predopterećenje',
          'upotrebljiv do -40°C',
          'ekološki prihvatljiva osnovna tekućina',
        ],
        description: [
          'CICO® SM 1600 B , univerzalna servisna i montažna pasta , kombinacija je kemijski tretiranih krutih maziva i biorazgradivog organskog nosača bez otapala.', 
          'CICO® SM 1600 B ne sadrži bakar i vrlo je ekološki prihvatljivo mazivo s učinkom smanjenja trošenja i odvajanja. Čak i tanka površinska folija nudi visoku razinu dugotrajne zaštite od habanja i korozije. Ekstremno visoka tlačna čvrstoća i optimalna otpornost na trenje nude ravnomjernije predopterećenje tijekom montaže. Demontaža je lakša i nakon godina.',
          'CICO® SM 1600 B štiti od korozije i habanja čak i pri visokim temperaturama do 1600°C iu korozivnoj atmosferi. Ekološki prihvatljiva pasta koristi se u svim područjima mehanike (npr. u kliznim ležajevima, vijčanim i fiksnim spojevima, vodilicama, svornjacima, klinovima, čahurama i šarkama, na spojevima, zglobovima, zupčanicima i žičanoj užadi).',
          'Čak i tanki podmazujući film djeluje optimalno. CICO® SM 1600 B se lako nanosi četkom ili lopaticom.',
          'CICO® SM 1600 B se isporučuje u sljedećim veličinama spremnika: spremnici od 1 kg, 5 kg i 10 kg. Ostale veličine spremnika na upit.',
        ],
        table: [
          ['NAZIV', 'CICO® SM 1600 B'],
          ['boja', 'Sivo'],
          ['Gustoća', '(na 20°C)	1,15 g/ cm3'],
          ['NLGI klasa', '(DIN 51818)	2 (tiksotropni)'],
          ['Viskoznost', '(na 40°C)	120 mm 2 /s (bazno ulje)'],
          ['otpornost na pritisak', 	'7,5 t/cm 2 (Bruggerova metoda)'],
          ['otpornost na temperaturu',	'-180°C do 1600°C'],
          ['topljivost u vodi',	'0,2 g/l'],
          ['plamište',	'>300°C'],
          ['temperatura paljenja',	'>350°C'],
          ['termalno raspadanje', 	'>350°C'],
          ['produkti razgradnje',	'ugljični monoksid'],
          ['Opasne reakcije',	'Ne'],
          ['Biorazgradivost (CEC-L-33-A-94)',	'>90% (bazno ulje)'],
          ['Klasa opasnosti od vode (WGK)',	'1'],
          ['Odlaganje (AVV)',	'130207'],
          ['prijevoz',	'Bez opasnih tvari'],
          ['stabilnost skladištenja',	'najmanje 2 godine'],
        ],
        image: img1,
      },
      {
        id: 9,
        name: 'CICO® AS 14',
        link: '',
        short: 'Antiseize - Razdjelna pasta | bez metala',
        bulletpoints: [
        'visoko učinkovita pasta za odvajanje za područje montaže',
        'Dugotrajna zaštita od korozije, zapinjanja, hrđanja',
        'koristi se kao brtvilo',
        'ne stvrdnjava se',
        'visoka tlačna čvrstoća i optimalna otpornost na trenje',
        'uzrokuje čak i predopterećenje',
        'Otporan na temperaturu od -100°C do 1600°C',
        'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® AS 14 , pasta za odvajanje protiv zahvata, je sredstvo za odvajanje bez metala, visokih performansi, napravljeno od kemijski tretiranih krutih maziva i biorazgradivog, organskog nosača bez otapala. Čak i tanki površinski film nudi visoku dugotrajnu zaštitu.', 
          'CICO® AS 14 se koristi u svim područjima mehanike: na vijcima, brtvama, zavrtnjima, klinovima, prešanim spojevima, utičnicama, maticama, utikačima, spojevima cijevi, za hladno i toplo oblikovanje, klinaste osovine, tokarenje smicanjem, centriranje injektiranja i još mnogo toga Posebno je prikladan za dijelove od nehrđajućeg čelika i čelika otpornog na kiseline, bakra i brončanih legura. Otpornost na visoke temperature sprječava izgaranje ispušnih sustava, brtvi, vijčanih spojeva itd',
          'Kao brtvilo, CICO® AS 14 je otporan na pritisak i temperaturu. Razdjelna pasta protiv zapinjanja se ne stvrdnjava i ne ostaje između nastavaka. Tretirani dijelovi mogu se bez problema rastaviti čak i godinama kasnije.',
          'CICO® AS 14 lako se nanosi četkom na prethodno odmašćene dijelove. CICO® Thinner K se preporučuje kao pomoć pri nanošenju .',
          'CICO® AS 14 dostupan je u sljedećim veličinama spremnika: spremnici od 1 kg, 5 kg i 10 kg. Ostale veličine spremnika na upit.',
        ],
        table: [
          ['NAZIV',	'CICO® AS 14'],
          ['NLGI klasa',	'2 (tiksotropni)'],
          ['Viskoznost (na 40°C)',	'120 mm 2 /s (bazno ulje)'],
          ['otpornost na pritisak',	'7,5 t/cm 2 (Bruggerova metoda)'],
          ['topljivost u vodi',	'0,2 g/l'],
          ['plamište',	'300°C'],
          ['temperatura paljenja',	'>350°C'],
          ['termalno raspadanje',	'>370°C'],
          ['produkti razgradnje',	'ugljični monoksid'],
          ['Opasne reakcije',	'Ne'],
          ['Biorazgradivost (CEC-L-33-A-94)',	'>90% (bazno ulje)'],
          ['Klasa opasnosti od vode', '(WGK)	1'],
          ['Odlaganje (AVV)',	'130207'],
          ['prijevoz',	'Bez opasnih tvari'],
          ['stabilnost skladištenja',	'najmanje 2 godine'],
        ],
        image: img2,
      },
      {
        id: 10,
        name: 'CICO® AH 1980 B',
        link: '',
        short: 'specijalno mazivo za otvorene zupčanike pri visokom tlaku i visokim temperaturama',
        bulletpoints: [
          'odličan učinak podmazivanja',
          'posebno razvijen za visoko opterećene otvorene zupčanike',
          'otporan na visoki pritisak i visoke temperature',
          'primjenjiv do -40°C',
          'Dugotrajna zaštita od habanja i korozije',
          'smanjena otpornost na trošenje i trenje',
          'visoka površinska adhezija',
          'bez gumiranja',
          'značajno dulji intervali održavanja',
          'mala potrošnja',
          'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® AH 1980 B , posebno mazivo za otvorene zupčanike pri visokom tlaku i visokoj temperaturi , sadrži kemijski tretirana čvrsta maziva na bazi biorazgradivog organskog nosača bez otapala. Posebno je razvijen za korištenje u otvorenim zupčanicima pri visokom tlaku i visokim temperaturama.', 
          'CICO® AH 1980 B ima dugotrajan učinak, smanjuje trenje i otpor, podmazuje i zaglađuje površine, smanjuje mikropukotine i jedva vidljivo izbijanje materijala.',
          'CICO® AH 1980 B karakterizira dobra stabilnost s visokim udjelom krutih tvari. Mazivo također podnosi ekstremna opterećenja okoline i tlaka te se može koristiti do -40°C. Zbog visokog prianjanja na metalnu površinu, aktivna tvar se ne može odbaciti. Ne dolazi do stvaranja desni.',
          'Čak i pri malim dozama, CICO® AH 1980 B stvara mazivi film koji izuzetno čvrsto prianja na metalne površine i stabilan je tijekom dugog vremenskog perioda te jamči konstantno podmazivanje. CICO® AH 1980 B učinkovito sprječava znakove istrošenosti i produljuje intervale održavanja.',
          'CICO® AH 1980 B doprinosi sigurnosti i stabilnosti kao i dugoročno učinkovitom smanjenju troškova i učinkovitosti.',
          'CICO® AH 1980 B je idealno mazivo za otvorene zupčanike. Može se nanositi odgovarajućom opremom za prskanje, a po potrebi i ručno.',
          'CICO® AH 1980 B se isporučuje u pakiranjima od 1 kg, 5 kg, 10 kg, 25 kg i 200 kg. Ostale veličine spremnika na upit.',
        ],
        table: [
          ['NAZIV',	'CICO® AH 1980 B'],
          ['boja',	'Sivo'],
          ['Gustoća (na 20°C)',	'1,01 g/ cm3'],
          ['Viskoznost (Brookfield LVT; 5,0 o/min, vreteno #1, 24°C)',	'1150 ],mPa.s'],
          ['Kinematička viskoznost (ISO 3104; na 24°C)',	'317 mm 2 /s'],
          ['NLGI klasa ', '(DIN 51818)	000'],
          ['temperatura primjene',	'-40°C do +90°C'],
          ['područje podmazivanja',	'-100°C do +500°C'],
          ['plamište',	'255ºC'],
          ['temperatura paljenja',	'>350°C'],
          ['termalno raspadanje',	'>350°C'],
          ['Opasne reakcije',	'Ne'],
          ['Biorazgradivost (CEC-L-33-A-94)',	'>90% (bazno ulje)'],
          ['Klasa opasnosti od vode (WGK)',	'1'],
          ['Odlaganje (AVV)',	'130207'],
          ['prijevoz',	'Bez opasnih tvari'],
          ['stabilnost skladištenja',	'najmanje 2 godine'],
        ],
        image: img2,
      },
      {
        id: 11,
        name: 'CICO® TGM 2000 K',
        link: '',
        short: 'sintetičko mazivo i konzervans - tekućina i sprej',
        bulletpoints: [
          'Podmazivanje i zaštita pokretnih dijelova u visoko korozivnim atmosferama',
          'dobra penetracija i visoka tlačna čvrstoća',
          'izuzetno dobra antikorozivna zaštita',
          'čisti i otpušta oksidirane i smolaste dijelove',
          'prodire i otpušta zaglavljene elemente (vijke, vijke, kočione papuče, vretena itd.)',
          'radi u temperaturnom rasponu od -70°C do +230°C',
          'stvara suhi klizni film dubok do pora',
          'ima učinak istiskivanja vlage',
          'antistatik, odbija prašinu i prljavštinu',
          'sprječava oksidaciju na mjestima električnog kontakta',
          'poboljšava vodljivost',
          'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® TGM 2000 K je sintetičko mazivo i konzervans koji je razvijen pomoću tehnologije aditiva. Teče i stvara tanak, nevidljiv i suh klizni i zaštitni film. Zahvaljujući izvrsnom kapilarnom učinku i učinku otapala, CICO® TGM 2000 K prodire u pore metalnih površina i najfinije pukotine. CICO® TGM 2000 K ne sadrži nikakve organske masti, naftne derivate, aditive silikona, grafita, molibden disulfida ili teflona i ne stvara nikakve produkte starenja niti naslage.', 
          'CICO® TGM 2000 K se uvlači i istiskuje vlagu. Stvara visoku razinu zaštite od korozije, nudi visok učinak čišćenja i otapala te omogućuje čisto podmazivanje i očuvanje pokretnih dijelova. Visoka otpornost na pritisak štiti površinu od trošenja i smanjuje otpor trenja.',
          'CICO® TGM 2000 K se koristi u montaži i servisu (npr. lanci i čelične sajle), kao iu preciznoj i finoj mehanici. Zahvaljujući visokom učinku prodiranja, teško dostupni elementi (brave, metarski mehanizmi, šarke, vodilice, alati za rezanje i mjerenje, elektroničke komponente itd.) također se mogu optimalno opskrbiti sintetičkim mazivom. CICO® TGM 2000 K produljuje vijek trajanja, smanjuje intervale podmazivanja i troškove održavanja.',
          'CICO® TGM 2000 K je tekućina s visokim učinkom penetracije, što ga čini vrlo jednostavnim za korištenje i učinkovitim čak i pri malim dozama. CICO® TGM 2000 K sprej se preporučuje za područja koja su posebno teško dostupna . Dodatni nastavak cijevi za raspršivanje koristi se za ciljanu upotrebu.',
          'CICO® TGM 2000 K se isporučuje u ambalaži od 5 l, 10 l i 25 l te kao sprej (400 ml). Ostale veličine spremnika na upit.',
          'Sprej je pod pritiskom. Ne smije doći u ruke djece i mora biti zaštićen od zagrijavanja i oštećenja. Nemojte prskati na otvoreni plamen ili bilo koji užareni predmet. Koristite samo na otvorenom ili u dobro prozračenom prostoru. Izbjegavajte udisanje aerosola i zaštitite oči. Odložiti samo kada je potpuno prazan.',
        ],
        table: [
          ['NAZIV',	'CICO® TGM 2000 K'],
          ['Miris',	'blaga'],
          ['fizičko stanje',	'tekućina'],
          ['Gustoća (na 20°C)',	'0,84 g/ cm3'],
          ['Kinematička viskoznost (na 40°C)', '150 mm2 / s'],
          ['Kinematička viskoznost (na 100°C)', '21 mm2 / s'],
          ['točka tečenja',	'-37°C'],
          ['plamište',	'>210°C'],
          ['termalno raspadanje',	'>300°C'],
          ['Opasne reakcije',	'Ne'],
          ['PBT i vPvB kriteriji (uredba REACH, Aneks XIII)',	'nije ispunjeno (nije postojano, nije bioakumulativno, nije toksično)'],
          ['Klasa opasnosti od vode (WGK)',	'1'],
          ['Odlaganje (AVV)',	'130206'],
          ['prijevoz',	'Bez opasnih tvari'],
          ['stabilnost skladištenja',	'najmanje 2 godine'],
          ['NAZIV',	'CICO® TGM 2000 K sprej'],
          ['oblik',	'Aerosol, djelatna tvar: tekućina'],
          ['boja',	'bezbojan'],
          ['Miris',	'karakteristika'],
          ['zapaljivo',	'ne odnosi se na aerosole'],
          ['Sadržaj otapala, VOC (EU)',	'91%'],
          ['temperatura samozapaljenja',	'ne odnosi se na aerosole'],
          ['Eksplozivna svojstva',	'nije eksplozivan moguće je stvaranje eksplozivne smjese para/zrak'],
          ['Opasne reakcije',	'Ne'],
          ['topljivost u vodi',	'nije rješivo'],
          ['Odlaganje (AVV)',	'150104 (metalna ambalaža) 150110 (može sadržavati ostatke opasnih tvari)'],
          ['prijevoz',	'UN1950 (Aerosoli)'],
          ['stabilnost skladištenja',	'najmanje 2 godine'],
        ],
        image: img2000k,
      },
      {
        id: 12,
        name: 'CICO® TGM 2000 B',
        link: '',
        short: 'Sintetičko univerzalno mazivo, pogodno za niske temperature',
        bulletpoints: [
          'Podmazivanje i konzerviranje također u rasponu niskih temperatura (-45°C do +230°C)',
          'posebno pogodan za pokretne dijelove u visoko korozivnim atmosferama',
          'vrlo dobra penetracija',
          'čisti površinu, otpušta hrđu',
          'otpušta gumirane dijelove',
          'sprječava zaleđivanje',
          'istiskivanje vlage',
          'odbija prašinu i prljavštinu',
          'anti statički',
          'ekološki prihvatljiv, bez opasnih tvari',
          'smanjuje napor održavanja',
        ],
        description: [
          'CICO® TGM 2000 B , sintetičko univerzalno mazivo za niskotemperaturno područje, biorazgradivo je sintetičko mazivo koje stvara nevidljivi zaštitni film i učinkovito je u širokom, čak i vrlo niskom temperaturnom rasponu od -45°C do +230° C. Ne sadrži organske masti, naftne derivate, silikonske aditive, grafit, molibden disulfid ili teflon i ne stvara proizvode starenja niti naslage. CICO® TGM 2000 Bodlikuje se izvrsnom oksidacijskom stabilnošću i izvrsnim tečenjem na hladnom (bez voska, maziv gotovo do točke stiništa). Ima optimalan kapilarni učinak, prodire u pore metalne površine i stvara čvrsto prianjajući, proziran, nemjerljiv razdjelni film čak i pri niskim temperaturama. Vrlo nizak dinamički koeficijent trenja (0,05) rezultira vrlo dobrim svojstvima klizanja. Otapa smolasta ulja i ostatke masti. CICO® TGM 2000 B pruža visoku zaštitu od korozije i sadrži visokotlačne aditive. Stoga je pogodan za podmazivanje svih pokretnih dijelova koji su izloženi velikim vibracijskim i udarnim opterećenjima.', 
          'CICO® TGM 2000 B štiti od habanja, smanjuje otpor trenja i produljuje životni vijek tretiranih elemenata.',
          'CICO® TGM 2000 B koristi se u svim područjima mehanike. Omogućuje čisto podmazivanje i očuvanje pokretnih dijelova, od jednostavnih kliznih ležajeva preko lanaca do precizne i fine mehanike. Zahvaljujući visokom učinku prodiranja, teško dostupni dijelovi također se mogu optimalno snabdjeti (npr. cilindri brava, metar mehanizmi, šarke, spojevi, navoji, klinovi, zasuni).',
          'CICO® TGM 2000 B se isporučuje u spremnicima od 5 l, 10 l i 25 l. Ostale veličine spremnika na upit.',
        ],
        table: [
          ['NAZIV',	'CICO® TGM 2000 B'],
          ['Miris',	'blaga'],
          ['fizičko stanje',	'tekućina'],
          ['Gustoća (na 20°C)',	'0,89 g/ cm3'],
          ['Viskoznost (na 40°C)',	'35 mm2 / s'],
          ['točka tečenja',	'-52°C'],
          ['plamište',	'250°C'],
          ['usredotočenost',	'>350°C'],
          ['termalno raspadanje',	'>350°C'],
          ['Opasne reakcije',	'Ne'],
          ['PBT i vPvB kriteriji (uredba REACH, Aneks XIII)',	'nije postojan, nije bioakumulativan, nije otrovan'],
          ['Klasa opasnosti od vode (WGK)',	'1'],
          ['Odlaganje (AVV)',	'130206'],
          ['prijevoz',	'Bez opasnih tvari'],
          ['stabilnost skladištenja',	'najmanje 2 godine'],
        ],
        image: img2000b,
      },
      {
        id: 13,
        name: 'CICO® TGM 2020',
        link: '',
        short: 'Visokoučinkovito mazivo za lance, ležajeve i zupčanike - tekućina i sprej',
        bulletpoints: [
          'učinkovit za visokoopterećene i brzobrzinske dijelove (zupčanici, ležajevi, lanci, sajle, navoji, mjerni mehanizmi)',
          'štiti i čuva metale i legure',
          'Čisti i otpušta oksidirane, zaglavljene i smolaste dijelove (zaglavljene vijke, vijke, kočione papuče, vretena itd.)',
          'dobro prianjajuća suha klizna folija, prozirna',
          'smanjuje gubitke trenjem i koroziju',
          'mazivi film otporan na visoke temperature (djelotvoran od -35°C, kontinuirano do +270°C, kratkotrajno do +300°C)',
          'odbija prašinu i prljavštinu',
          'istiskivanje vlage',
          'bez mineralnih ulja',
          'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® TGM 2020 je visokokvalitetno sintetičko mazivo i konzervans visoke viskoznosti. Formira snažno prianjajući, prozirni zaštitni film s visokim učinkom penetracije i učinkovit je u rasponu temperatura od -35°C do raspona visokih temperatura od +270°C, kratko do +300°C. CICO® TGM 2020 ne sadrži nikakve organske masti, naftne derivate, aditive silikona, grafita, molibden disulfida ili teflona. Ne stvara proizvode starenja niti naslage. Zahvaljujući izvrsnom kapilarnom učinku, prodire u pore metalnih površina, otapa ih i čisti te stvara nemjerljiv, na visoki tlak otporan razdjelni i podmazujući film.', 
          'CICO® TGM 2020 posebno je prikladan za podmazivanje visokoopterećenih, brzih i teško dostupnih dijelova u širokom rasponu temperatura i s velikim vibracijskim i udarnim opterećenjima (npr. zupčanici, kočnice, pećnice, klizni i valjkasti ležajevi, spojevi, lanci, žičana užad itd.). Koristi se u svim područjima mehanike, od jednostavnih kliznih ležajeva i lanaca do uređaja za preciznu i finu mehaniku. CICO® TGM 2020 nudi visoku zaštitu od korozije i čisto podmazivanje kao i visok učinak čišćenja i otapala. Povećava vijek trajanja i smanjuje troškove održavanja.',
          'CICO® TGM 2020 može se koristiti kao tekuće mazivo i konzervans u svim područjima mehanike i preciznog inženjerstva. Lako se nanosi, ima visok učinak penetracije i djeluje kao tanak film čak i pri malim dozama. CICO® TGM 2020 sprej se preporučuje za područja koja su posebno teško dostupna . Ovdje se nastavak cijevi za prskanje također koristi za ciljanu upotrebu. CICO® TGM 2020 se isporučuje u ambalaži od 5 l, 10 l i 25 l te kao sprej (400 ml). Ostale veličine spremnika na upit.',
          'Sprej je pod pritiskom. Ne smije doći u ruke djece i mora biti zaštićen od zagrijavanja i oštećenja. Nemojte prskati na otvoreni plamen ili bilo koji užareni predmet. Koristite samo na otvorenom ili u dobro prozračenom prostoru. Izbjegavajte udisanje aerosola i zaštitite oči. Odložiti samo kada je potpuno prazan.',
        ],
        table: [
          ['NAZIV	CICO®', 'TGM 2020'],
          ['Miris',	'blaga'],
          ['psihičko stanje',	'tekućina'],
          ['Gustoća (na 20°C)',	'0,86 g/ cm3'],
          ['Kinematička viskoznost (na 40°C)',	'215 mm2 / s'],
          ['točka tečenja',	'-39°C'],
          ['plamište',	'>215°C'],
          ['termalno raspadanje',	'>300°C'],
          ['Opasne reakcije',	'Ne'],
          ['PBT ili vPvB kriteriji prema Uredbi REACH, Dodatak XIII',	'nije ispunjeno (nije postojano, nije bioakumulativno, nije toksično)'],
          ['Klasa opasnosti od vode (WGK)',	'1'],
          ['Odlaganje (AVV)',	'130206'],
          ['prijevoz',	'Bez opasnih tvari'],
          ['stabilnost skladištenja',	'najmanje 2 godine'],
          ['NAZIV',	'CICO® TGM 2020 sprej'],
          ['oblik',	'Aerosol, djelatna tvar: tekućina'],
          ['boja',	'žuta boja'],
          ['Miris',	'karakteristika'],
          ['temperatura paljenja',	'ne odnosi se na aerosole'],
          ['Sadržaj otapala,', 'VOC (EU)	91%'],
          ['temperatura samozapaljenja',	'ne odnosi se na aerosole'],
          ['Eksplozivna svojstva',	'nije eksplozivan, moguće je stvaranje eksplozivne smjese para/zrak'],
          ['Opasne reakcije',	'Ne'],
          ['topljivost u vodi', '	nije rješivo'],
          ['Odlaganje (AVV)',	'150104 (metalna ambalaža) 150110 (može sadržavati ostatke opasnih tvari)'],
          ['prijevoz',	'UN1950 (Aerosoli)'],
          ['stabilnost skladištenja',	'najmanje 2 godine'],
        ],
        image: img2000b,
      },
      {
        id: 14,
        name: 'CICO® TGM 5000',
        link: '',
        short: 'Potpuno sintetičko mazivo za ležajeve i zupčanike',
        bulletpoints: [
          'Podmazivanje i očuvanje pokretnih dijelova, posebno ležajeva i zupčanika',
          'otporan na visoke temperature do +250°C',
          'visoka tlačna čvrstoća',
          'dobra penetracija',
          'izuzetno dobra antikorozivna zaštita',
          'stvara suhi klizni film dubok do pora',
          'ima učinak istiskivanja vlage',
          'ne privlači prašinu i prljavštinu',
          'čisti i otpušta zahvaćene dijelove',
          'netoksičan',
          'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® TGM 5000 je potpuno sintetičko mazivo i konzervans na bazi sintetičkih ugljikovodika. Mazivo je razvijeno za primjene kod kojih se visoki zahtjevi za podmazivanjem više ne mogu pokriti mineralnim uljima. Odlikuje se izvrsnom oksidacijskom stabilnošću, izvrsnim ponašanjem pri hladnom tečenju (bez voska, maziv gotovo do točke tečenja), posebnim svojstvima podmazivanja, povoljnim viskoznim/temperaturnim ponašanjem i postojanošću na smicanje. CICO® TGM 5000ne sadrži organske masti, naftne derivate, aditive silikona, grafita, molibden disulfida ili teflona i ne stvara nikakve produkte starenja niti naslage. Proizvod je prikladan za dugotrajne temperature uljne kupke do 150°C. Vršne temperature koje se javljaju u mazivom filmu mogu biti znatno više.', 
          'CICO® TGM 5000 stvara tanak, nevidljiv i suh klizni i zaštitni film. Zahvaljujući izvrsnom kapilarnom i otapalnom učinku, prodire u pore metalnih površina i najfinije pukotine. Visoka otpornost na pritisak štiti površinu od trošenja i smanjuje otpor trenja. CICO® TGM 5000 nudi visoku razinu zaštite od korozije i čistog podmazivanja, kao i visok učinak čišćenja i otapala. Mazivo produljuje životni vijek i smanjuje intervale podmazivanja i troškove održavanja.',
          'CICO® TGM 5000 koristi se za ležajeve i zupčanike s najvišim zahtjevima za podmazivanje. Tanak film je već učinkovit. U isto vrijeme, CICO® TGM 5000 je kompatibilan s komercijalno dostupnim brtvama (npr. izrađene od NBR, ACM, FKM, VMQ) i omogućuje korištenje istih uređaja za njegu (filteri, separatori itd.) kao i s mineralnim uljima. Proizvod se može miješati s mineralnim uljima. Sadržaj mineralnih ostataka ulja ne smije prelaziti 5% kako bi se održala svojstva maziva. Zbog širokog raspona primjena i visokog učinka penetracije, CICO® TGM 5000 također se može koristiti kao mazivo i konzervans u područjima oko mjenjača. CICO® TGM 5000 je netoksičan i ne razvija neugodan miris.',
          'CICO® TGM 5000 se isporučuje u posudama od 5 l, 10 l i 25 l. Ostale veličine spremnika na upit.',
        ],
        table: [
          ['NAZIV',	'CICO® TGM 5000'],
          ['Miris',	'blaga'],
          ['fizičko stanje',	'tekućina'],
          ['Gustoća (na 20°C)',	'0,85 g/ cm3'],
          ['Kinematička viskoznost (na 40°C)',	'145  mm2 / s'],
          ['Kinematička viskoznost (na 100°C)',	'18,9 mm2 / s'],
          ['točka tečenja',	'-38°C'],
          ['plamište',	'218'],
          ['termalno raspadanje',	'>300°C'],
          ['Opasne reakcije',	'Ne'],
          ['PBT i vPvB kriteriji (uredba REACH, Aneks XIII)',	'nije postojan, nije bioakumulativan, nije otrovan'],
          ['Klasa opasnosti od vode (WGK)',	'1'],
          ['Odlaganje (AVV)',	'130206'],
          ['prijevoz',	'Bez opasnih tvari'],
          ['stabilnost skladištenja',	'najmanje 2 godine'],
        ],
        image: img2000b,
      },
      {
        id: 15,
        name: 'CICO® H 3000',
        link: '',
        short: 'Biorazgradivo sintetičko hidrauličko ulje',
        bulletpoints: [
          'stabilno multigradno hidrauličko ulje',
          'učinkovit u širokom rasponu temperatura (-60°C do +200°C)',
          'visoka viskoznost',
          'visoka zaštita od korozije i habanja',
          'nema sklonosti pjenjenju',
          'odvaja vodu',
          'ne napada brtve ili strojne boje',
          'može se miješati s hidrauličkim uljima na bazi mineralnog ulja',
          'bez mineralnih ulja, više od 90% biorazgradivo (bazno ulje)',
          'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® H 3000 je bez mineralnog ulja, ekološki prihvatljivo i istovremeno izuzetno stabilno multigradno hidraulično ulje. Koristi se u hidrauličkim sustavima i hidrostatskim pogonima građevinskih strojeva, vozila, alatnih strojeva, preša i druge opreme.', 
          'CICO® H 3000 je biorazgradivo i stoga manje štetno za okoliš od uobičajenih hidrauličnih ulja na bazi mineralnih ulja. Proizvod se prvenstveno koristi kada postoji opasnost od istjecanja hidrauličkog ulja u tlo ili vodu.',
          'Prilikom prelaska na CICO® H 3000 prethodno čišćenje ili ispiranje nije potrebno. Međutim, jednostavno nadopunjavanje se ne preporučuje (nemojte miješati s drugim proizvodom).',
          'CICO® H 3000 dostupan je u sljedećim veličinama spremnika: spremnici od 5 l, 10 l i 25 l. Ostale veličine spremnika na upit.',
        ],
        table: [
          ['NAZIV',	'CICO® TGM 3000'],
          ['Miris',	'blaga'],
          ['fizičko stanje',	'tekućina'],
          ['Gustoća (na 20°C)',	'0,9 g/ cm3'],
          ['Viskoznost (na 40°C)',	'27 mm2 / s'],
          ['točka tečenja',	'-60°C'],
          ['plamište',	'270°C'],
          ['usredotočenost',	'>375°C'],
          ['termalno raspadanje',	'>350°C'],
          ['Opasne reakcije',	'Ne'],
          ['Biorazgradivost (CEC-L-33-A-94)',	'>90% (bazno ulje)'],
          ['Klasa opasnosti od vode (WGK)',	'1'],
          ['Odlaganje (AVV)',	'130206'],
          ['prijevoz',	'Bez opasnih tvari'],
          ['stabilnost skladištenja',	'najmanje 2 godine'],
        ],
        image: img2000b,
      },
      {
        id: 16,
        name: 'CICO® Thinner K',
        link: '',
        short: 'Biorazgradivi razrjeđivač',
        bulletpoints: [
          'F vrlo učinkovit razrjeđivač',
          'otapa ostatke gumene masti',
          'čisti površinu',
          'visoko kapilarno djelovanje; prodire u mikroskopske pore i pukotine',
          'puzi pod vodom i stvara zaštitni film protiv vlage',
          'ne privlači prašinu i prljavštinu',
          'biorazgradivo (preko 80% prema CEC-W-33-TL-82)',
          'ekološki prihvatljiv, bez opasnih tvari F',
        ],
        description: [
          'CICO® Thinner K je biorazgradivi, sintetički razrjeđivač. Ne sadrži naftne derivate niti silikonske dodatke. Zbog izvrsnih svojstava otapanja, razrjeđivač je općenito učinkovit kao otapalo od -45°C do +200°C. Koristi se za uklanjanje gumastih ostataka masti na raznim metalnim dijelovima i kao otapalo za sve masti (ne za fluorirane kemikalije).', 
          'CICO® Thinner K je posebno razvijen za razrjeđivanje montažne paste CICO® SM 1200 B. Olakšava nanošenje CICO® pasta SM 1200 B, SM 1600 B i AS 14 . Tekuća mješavina ima odličan kapilarni učinak i prodire u pore metalne površine. Nizak dinamički koeficijent trenja (0,1) razrijeđene montažne paste rezultira vrlo dobrim kliznim svojstvima, što čini CICO® SM 1200 B pogodnim za podmazivanje pokretnih dijelova koji su također izloženi velikim vibracijama i udarnim opterećenjima (npr. spojevi, vijci, itd.). ).',
          'CICO® Thinner K općenito je vrlo učinkovito sredstvo za čišćenje i otapalo.',
          'CICO® Thinner K kao razrjeđivač za paste CICO® SM 1200 B, CICO® SM 1600 B i CICO® AS 14 . Paste se mogu razrijediti s CICO® Thinner K do najviše 20%. Razrjeđivanje preko 20% moglo bi utjecati na prianjanje na metal i ne preporučuje se za teške strojne dijelove.',
          'CICO® Thinner K se isporučuje u spremnicima od 5 kg i 10 kg. Ostale veličine spremnika na upit.',
        ],
        table: [
          ['NAZIV',	'CICO® Thinner K '],
          ['Gustoća (na 20°C)',	'0,8 g/ cm3'],
          ['Viskoznost (na 40°C)',	'28 mm2 / s'],
          ['Topivost u vodi (na 24°C)',	'netopljiv'],
          ['plamište',	'244°C'],
          ['temperatura samozapaljenja',	'350°C'],
          ['temperatura raspadanje',	'>350°C'],
          ['Opasni produkti raspadanja',	'ugljični monoksid'],
          ['Opasne reakcije',	'Ne'],
          ['Biorazgradivost (CEC-L-33-A-94)',	'>90% (bazno ulje)'],
          ['Klasa opasnosti od vode (WGK)',	'1'],
          ['Odlaganje (AVV)',	'130207'],
          ['prijevoz',	'Bez opasnih tvari'],
          ['stabilnost skladištenja',	'najmanje 2 godine'],
        ],
        image: imgRaz,
      },
      {
        id: 17,
        name: 'CICO® TL 22 B',
        link: '',
        short: 'mazivo za prirubnice kotača',
        bulletpoints: [
          'smanjuje trošenje rubova kotača i rubova tračnica',
          'bez pukotina ili metalnih krhotina',
          'visoka površinska adhezija, visoka tlačna čvrstoća',
          'nema bacanja',
          'bez gumiranja',
          'značajno dulji intervali održavanja',
          'primjenjiv do -40°C',
          'mala potrošnja',
          'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® TL 22 B , mazivo za prirubnice kotača , kombinacija je kemijski tretiranih krutih maziva i biorazgradivog organskog nosača bez otapala. Posebno je razvijen za upotrebu u posebnim sustavima za prskanje prirubnice kotača kako bi se dugoročno učinkovito smanjilo trenje pri kotrljanju i klizanju između prirubnice kotača i bokova tračnice te da bi se značajno smanjilo trošenje i buka.', 
          'CICO® TL 22 B karakterizira dobra stabilnost s visokim udjelom krutih tvari. Također podnosi ekstremna opterećenja okoline i tlaka i može se koristiti do -40°C. Zbog visoke razine prianjanja na metalnu površinu, gotovo je nemoguće da aktivna tvar bude izbačena iz prirubnice kotača. Ne dolazi do stvaranja desni. CICO® TL 22 B uklanja postojeće male pukotine, sprječava daljnje stvaranje pukotina i lomljenje metala na prirubnici kotača i bokovima tračnica, smanjuje otpor kotrljanja i trošenje. Čak iu malim dozama stvara mazivi film koji izuzetno čvrsto prianja na metalne površine i stabilan je tijekom dugog vremenskog perioda te jamči konstantno podmazivanje. Učinak kočenja povećava CICO® TL 22 Bne utječe i razina buke je smanjena.',
          'CICO® TL 22 B doprinosi sigurnosti i stabilnosti vozila, kao i dugoročno učinkovitom smanjenju troškova i učinkovitosti.',
          'CICO® TL 22 B je idealno mazivo za sustave za prskanje prirubnica kotača koji mogu isporučiti maziva za prirubnice kotača koja sadrže visoku količinu krutine. Uz odgovarajuće sustave prskanja, proizvod je također prikladan za vrste zupčanika kao što su prstenasti zupčanici.',
          'CICO® TL 22 B se isporučuje u pakiranjima od 5 kg, 10 kg, 25 kg i 200 kg. Ostale veličine spremnika na upit.',
        ],
        table: [
          [
            'NAZIV',
            'CICO® TL 22 B',
          ],
          [
            'Gustoća (na 20°C)',
            '1,03 g/ cm3',
          ],
          [
            'Viskoznost (Brookfield LVT; 5,0 o/min, vreteno #1, 24°C)',
            '1150 mPa.s',
          ],
          [
            'Kinematička viskoznost (ISO 3104; na 24°C)',
            '327 mm 2 /s',
          ],
          [
            'NLGI klasa (DIN 51818)',
            '000',
          ],
          [
            'temperatura primjene',
            '-40°C do +90°C',
          ],
          [
            'područje podmazivanja',
            '-100°C do +500°C',
          ],
          [
            'plamište',
            '255°C',
          ],
          [
            'temperatura paljenja',
            '>350°C',
          ],
          [
            'termalno raspadanje',
            '>350°C',
          ],
          [
            'Opasne reakcije',
            'Ne',
          ],
          [
            'Biorazgradivost (CEC-L-33-A-94)',
            '>90% (bazno ulje)',
          ],
          [
            'Klasa opasnosti od vode (WGK)',
            '1',
          ],
          [
            'Odlaganje (AVV)',
            '130207',
          ],
          [
            'prijevoz',
            'Bez opasnih tvari',
          ],
          [
            'stabilnost skladištenja',
            'najmanje 2 godine',
          ],
        ],
        image: img1,
      },
      {
        id: 18,
        name: 'CICO® TL 1500 B',
        link: '',
        short: 'mazivo za prirubnice kotača',
        bulletpoints: [
          'Smanjenje trošenja i buke',
          'kontrolirana tiksotropna svojstva',
          'visoka tlačna čvrstoća',
          'ne veže prašinu',
          'visoka površinska adhezija',
          'bez gumiranja',
          'Otpornost na temperaturu mazivog filma od -100°C do +500°C',
          'primjenjiv do -40°C',
          'mala potrošnja',
          'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® TL 1500 B , mazivo za prirubnice kotača , kombinacija je visoko postotnih kemijski tretiranih krutih maziva i biorazgradivog organskog nosača bez otapala. Razvijen je za smanjenje dugotrajnog trenja pri kotrljanju i klizanju između prirubnice kotača i bokova tračnice do kojeg dolazi pod ekstremnim opterećenjima okoline i tlaka pri upravljanju željezničkim vozilima te za značajno smanjenje trošenja. ', 
          'CICO® TL 1500 B uklanja male pukotine. Kao rezultat toga, više se ne mogu vidjeti pukotine i izbijanje metala na prirubnici kotača i bokovima tračnice. Snažno prijanjajući film za odvajanje karakterizira dobra stabilnost s visokim udjelom krutih tvari. Osigurava suhu, homogenu površinsku oblogu uz vrlo malu potrošnju. Odbacivanje ili oštećenje okolnih komponenti gotovo je nemoguće. ',
          'CICO® TL 1500 B smanjuje otpor kotrljanja i trošenje. CICO® TL 1500 B ne smanjuje učinak kočenja i smanjuje razinu buke. Zajamčena je visoka razina operativne pouzdanosti.',
          'CICO® TL 1500 B doprinosi sigurnosti i stabilnosti vozila, kao i dugoročno učinkovitom smanjenju troškova i učinkovitosti.',
          'U kombinaciji s odgovarajućim načinom nanošenja, CICO® TL 1500 B stvara mazivi film koji se ne može otopiti uobičajenim sredstvima za čišćenje i koji izuzetno čvrsto prianja na metalne površine, čak i pri malim dozama. Izbjegava se veza s abrazijom tračnica i cestovnom prljavštinom. Dugotrajni film za razdvajanje i podmazivanje sprječava neispravno zaleđivanje tijekom zimskog razdoblja. Trošenje i buka značajno su smanjeni.',
          'CICO® TL 1500 B se isporučuje u pakiranjima od 5 kg, 10 kg, 25 kg i 200 kg. Ostale veličine spremnika na upit.',
        ],
        table: [
          [
            'NAZIV',
            'CICO® TL 1500 B',
          ],
          [
            'Gustoća (na 20°C)',
            '1,03 g/ cm3',
          ],
          [
            'Viskoznost (Brookfield LVT; 5,0 o/min, vreteno #1, 24°C)',
            '1150 mPa.s',
          ],
          [
            'Kinematička viskoznost (ISO 3104; na 24°C)',
            '324 mm2 / s',
          ],
          [
            'NLGI klasa (DIN 51818)',
            '000',
          ],
          [
            'temperatura primjene',
            '-40°C do +90°C',
          ],
          [
            'područje podmazivanja',
            '-100°C do +500°C',
          ],
          [
            'plamište',
            '255°C',
          ],
          [
            'temperatura paljenja',
            '>350°C',
          ],
          [
            'termalno raspadanje',
            '>350°C',
          ],
          [
            'Opasne reakcije',
            'Ne',
          ],
          [
            'Biorazgradivost (CEC-L-33-A-94)',
            '>90% (bazno ulje)',
          ],
          [
            'Klasa opasnosti od vode (WGK)',
            '1',
          ],
          [
            'Odlaganje (AVV)',
            '130207',
          ],
          [
            'prijevoz',
            'Bez opasnih tvari',
          ],
          [
            'stabilnost skladištenja',
            'najmanje 2 godine',
          ],
        ],
        image: img1,
      },
      {
        id: 19,
        name: 'CICO® TL 1600 B',
        link: '',
        short: 'mazivo za kotače i zupčanike',
        bulletpoints: [
          'Smanjenje trošenja i buke na prirubnici kotača i lančaniku',
          'Sprječava stvaranje pukotina i erupciju metala (npr. lomove vijenca zupčanika)',
          'visoka stabilnost',
          'Otpornost na temperaturu mazivog filma od -90°C do +550°C',
          'upotrebljiv do -40°C',
          'visoka tlačna čvrstoća i visoka površinska adhezija',
          'nema bacanja',
          'nema gumiranja, ne veže prljavštinu',
          'mala potrošnja',
          'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® TL 1600 B , mazivo za kotače i zupčanike , kombinacija je kemijski tretiranih krutih maziva i biorazgradivog organskog nosača bez otapala. Posebno je razvijen za prstenaste zupčanike i druge otvorene sustave zupčanika kako bi se smanjilo trenje kotrljanja i klizanja koje se dugoročno javlja pod ekstremnim tlačnim opterećenjima i značajno smanjilo trošenje. Učinkovito je spriječeno pucanje i izbijanje metala na rubu zupčanika. Čak i pri niskim dozama, CICO® TL 1600 B stvara mazivi film koji se ne može ukloniti uobičajenim sredstvima za čišćenje i izuzetno čvrsto prianja na metalne površine.', 
          'Čvrsto prianjajući film za razdvajanje i podmazivanje osigurava suhu, homogenu površinsku prevlaku uz samo malu potrošnju. Također je otporan na ekstremna tlačna i temperaturna opterećenja tijekom dugog vremenskog razdoblja. Odbacivanje ili oštećenje okolnih komponenti gotovo je nemoguće. To jamči stalno podmazivanje i značajno smanjenje trošenja, kao i visoku radnu pouzdanost.',
          'CICO® TL 1600 B doprinosi sigurnosti i stabilnosti vozila, kao i dugoročno učinkovitom smanjenju troškova i učinkovitosti.',
          'CICO® TL 1600 B može se koristiti na temperaturama do -40 °C pomoću odgovarajućeg sustava crpke. Otpornost na temperaturu dugotrajnog filma za razdvajanje i podmazivanje zajamčena je s dosljednom funkcijom od -90°C do +550°C.',
          'CICO® TL 1600 B se isporučuje u spremnicima od 10 kg, 25 kg i 200 kg. Ostale veličine spremnika na up',
        ],
        table: [
          [
            'NAZIV',
            'CICO® TL 1600 B',
          ],
          [
            'boja',
            'Sivo',
          ],
          [
            'Gustoća (ISO 12185) na 20°C',
            '1,03 g/ cm3',
          ],
          [
            'Viskoznost (Brookfield LVT; 5,0 o/min, vreteno #1, 24°C)',
            '1150 mPa.s',
          ],
          [
            'Kinematička viskoznost (ISO 3104; na 24°C)',
            '327 mm 2 /s',
          ],
          [
            'NLGI klasa (DIN 51818)',
            '000',
          ],
          [
            'temperatura primjene',
            '-40°C do +90°C',
          ],
          [
            'područje podmazivanja',
            '-100°C do +500°C',
          ],
          [
            'plamište',
            '255°C',
          ],
          [
            'temperatura paljenja',
            '>350°C',
          ],
          [
            'termalno raspadanje',
            '>350°C',
          ],
          [
            'Opasne reakcije',
            'Ne',
          ],
          [
            'Biorazgradivost (CEC-L-33-A-94)',
            '>90% (bazno ulje)',
          ],
          [
            'Klasa opasnosti od vode (WGK)',
            '1',
          ],
          [
            'Odlaganje (AVV)',
            'broj 130207',
          ],
          [
            'prijevoz',
            'Bez opasnih tvari',
          ],
          [
            'stabilnost skladištenja',
            'najmanje 2 godine',
          ],
        ],
        image: img1,
      },
      {
        id: 20,
        name: 'CICO® SM 1200 MB',
        link: '',
        short: 'sredstvo za tretiranje glave tračnica',
        bulletpoints: [
          'eliminira škripu u zavojima',
          'sprječava pucanje i lomljenje metala',
          'primjenjiv do -40°C',
          'otporan na visoke temperature',
          'otporan na vodu, slanu vodu i kemikalije',
          'ekološki prihvatljiv, bez opasnih tvari',
          'Nanošenje ručno ili odgovarajućim sustavom prskanja',
        ],
        description: [
          'CICO® SM 1200 MB , sredstvo za tretiranje glave tračnica , čvrsto je sredstvo za tretiranje posebno formulirano na metalnoj osnovi za sprječavanje škripanja u zavojima u željezničkim vozilima.', 
          'CICO® SM 1200 MB odmah i uspješno otklanja neugodno škripanje na zavojima. Zahvaljujući izvanrednom prianjanju, čak i nanošenje tankog filma aktivne tvari na glavu tračnice nudi dugotrajnu zaštitu i smanjenje abrazije tračnice na kritičnim točkama. Zbog prirode proizvoda i načina primjene nemoguć je spoj sa željezničkom i cestovnom prljavštinom. Kondicioniranje suhe površine postiže se CICO® SM 1200 MB',
          'CICO® SM 1200 MB također prianja na mokre tračnice. Aktivna tvar ravnomjerno se raspoređuje sljedećim kotačima. To stvara tanku površinsku prevlaku koju je potrebno samo obnavljati u skladu sa stupnjem opterećenja u zavojima i vremenskim utjecajima. Pogoršanje učinka kočenja ili usporavanje kočenja jedva se ili uopće ne može uočiti.',
          'Kod ručnog nanošenja CICO® SM 1200 MB četkom, sredstvo se nanosi što je moguće tanje u području krivine: počevši od početka krivine na obje glave tračnica u trakama duljine 10 cm i razmaka 5 m.',
          'CICO® SM 1200 MB također se može nanijeti na glavu tračnice pomoću odgovarajućeg visokotlačnog sustava za prskanje. Takvi sustavi mogu se ugraditi u željeznička vozila ili na posebna vozila za održavanje tračnica.',
          'CICO® SM 1200 MB je ekološki prihvatljiv i bezopasan. Nosač je biorazgradiv.',
          'CICO® SM 1200 MB isporučuje se u spremnicima od 1 kg, 5 kg i 10 kg. Ostale veličine spremnika na upit.',
        ],
        table: [
          [
            'NAZIV',
            'CICO® SM 1200MB',
          ],
          [
            'boja i oblik',
            'siva, pasta',
          ],
          [
            'Gustoća (na 20°C)',
            '1,2 g/ cm3',
          ],
          [
            'Viskoznost (na 40°C)',
            '45 mm 2 /s (bazno ulje)',
          ],
          [
            'NLGI klasa',
            '2',
          ],
          [
            'topljivost u vodi',
            '0,2 g/l',
          ],
          [
            'plamište	',
            '300°C',
          ],
          [
            'temperatura paljenja',
            '>450°C',
          ],
          [
            'termalno raspadanje',
            '>350°C',
          ],
          [
            'Opasne reakcije',
            'Ne',
          ],
          [
            'Biorazgradivost (CEC-L-33-A-94)',
            '>90% (bazno ulje)',
          ],
          [
            'Klasa opasnosti od vode (WGK)',
            '1',
          ],
          [
            'Odlaganje (AVV)',
            '130207',
          ],
          [
            'prijevoz',
            'Bez opasnih tvari',
          ],
          [
            'stabilnost skladištenja',
            'najmanje 2 godine',
          ],
        ],
        image: img2,
      },
      {
        id: 21,
        name: 'CICO® WSM 3000 T',
        link: '',
        short: 'mazivo za točke',
        bulletpoints: [
          'primjenjiv do -50°C',
          'učinkovit čak i pri ekstremno visokim temperaturama',
          'sprječava zaleđivanje',
          'ne veže prljavštinu',
          '(morski) vodootporan',
          'mala potrošnja',
          'ekološki prihvatljiv, bez opasnih tvari',
        ],
        description: [
          'CICO® WSM 3000 T , mazivo za prekidače , kombinacija je kemijski tretiranih krutih maziva i biorazgradivog, organskog nosača. Razvijen je kako bi osigurao besprijekoran rad točaka čak i pod ekstremnim vremenskim uvjetima.', 
          'CICO® WSM 3000 T stvara izuzetno ljepljiv mazivi film čak i pri malim dozama. Također je otporan na ekstremne temperature tijekom dugog vremenskog razdoblja i sprječava neispravno zaleđivanje tijekom zimskog razdoblja. Čak i pri ekstremno visokim temperaturama ljeti, konstantno podmazivanje je zajamčeno. Dugotrajni film za razdvajanje i podmazivanje ne veže prašinu, metalne ostatke ili druga onečišćenja. CICO® WSM 3000 T je niske potrošnje.',
          'Korištenje CICO® WSM 3000 T rezultira značajno povećanom učinkovitosti rada vlaka kroz poboljšanu funkcionalnost i sigurnost skretnica uz značajno smanjene troškove održavanja.',
          'CICO® WSM 3000 T treba primijeniti na prekidače koji su što čistiji. Može se nanositi četkama, špricama, pumpicama i svim sustavima za raspršivanje prikladnim za čvrsta maziva.',
          'CICO® WSM 3000 T isporučuje se u spremnicima od 5 kg, 10 kg, 25 kg i 200 kg. Ostale veličine spremnika na upit.',
        ],
        table: [
          [
            'NAZIV',
            'CICO® WSM 3000 T',
          ],
          [
            'Gustoća (ISO 12185; na 20°C)	',
            '1,01 g/ cm3',
          ],
          [
            'Kinematička viskoznost (ISO 3104)	na 25°C',
            '0,192 Pas',
          ],
          [
            'Kinematička viskoznost (ISO 3104)	na -35°C',
            '9.10 Pas',
          ],
          [
            'plamište',
            '250°C',
          ],
          [
            'temperatura paljenja',
            '>400°C',
          ],
          [
            'točka tečenja',
            '-55°C',
          ],
          [
            'termalno raspadanje',
            '>350°C',
          ],
          [
            'otpornost na temperaturu',
            '-100°C do +600°C',
          ],
          [
            'Opasne reakcije',
            'Ne',
          ],
          [
            'Biorazgradivost (CEC-L-33-A-94)',
            '>90% (bazno ulje)',
          ],
          [
            'Klasa opasnosti od vode (WGK)',
            '1',
          ],
          [
            'Odlaganje (AVV)',
            '130207',
          ],
          [
            'prijevoz',
            'Bez opasnih tvari',
          ],
          [
            'stabilnost skladištenja',
            'najmanje 2 godine',
          ],
        ],
        image: img1,
      },
    ],
    productImage: p2
  },
  {
    id: 3,
    title: 'VISOKA KVALITETA ZA OBRTNIKE',
    short: 'Sprejevi za podmazivanje i konzerviranje',
    description: [
      'CICO® sprejevi za podmazivanje i konzerviranje namijenjeni su za upotrebu u radionicama i kućanstvu. Lako ih je prskati i lako ih je razlikovati - dovoljan je film tanak kao vafel. ', 
      'CICO® sprejevi su učinkoviti, čisti i mogu se koristiti gdje god je potrebna najviša razina podmazivanja i očuvanja u finoj i preciznoj mehanici ili na velikim strojevima.'],
    products: [
      {
        id: 23,
        name: 'CICO® HWS - sprej za obrtnike, sprej za podmazivanje i konzerviranje | Sivo',
        link: '',
        short: '',
        bulletpoints: [
          'Visokoučinkovit sprej za trgovinu i industriju',
          'olakšava montažu i demontažu',
          'Dugotrajna zaštita od korozije, habanja i habanja',
          'posebno pogodan za ležajeve, zupčanike, vijke i fiksne spojeve',
          'otporan na visoke temperature i tlak (ispušni sustavi)',
          'tanak film, optimalno učinkovit',
          'otporan na slanu vodu i kemikalije',
        ],
        description: [
          'CICO® HWS - Craftsman Spray je visokoučinkoviti sprej koji sadrži krutine. Čak i tanki površinski film optimalno podmazuje i odvaja. Pruža dugotrajnu zaštitu od zapinjanja, habanja i korozije u vijčanim i fiksnim spojevima, kliznim ležajevima, zupčanicima, ispušnim sustavima čak i pri ekstremnim temperaturama (-40°C-1200°C) i visokom tlaku (do 7,5 t/cm 2 ). Na platformama osigurava ravnomjernije predopterećenje. Također preporučujemo CICO® HWS T za rad na vidljivim područjima kao što su prozori, vrata i brave.', 
          'CICO® HWS - Craftsman Spray i CICO® HWS T - Craftsman Spray prozirni se isporučuju u sprej limenkama od 400 ml. Za optimalnu primjenu koristi se dodatni nastavak cijevi za raspršivanje. Snažno protresite limenku prije upotrebe - dok ne čujete kuglicu.',
          'Sprej je pod pritiskom. Ne smije doći u ruke djece i mora biti zaštićen od zagrijavanja i oštećenja. Nemojte prskati na otvoreni plamen ili bilo koji užareni predmet. Koristite samo na otvorenom ili u dobro prozračenom prostoru. Izbjegavajte udisanje aerosola i zaštitite oči. Odložiti samo kada je potpuno prazan.',
        ],
        table: [
          [
            'NAZIV',
            'aerosol',
          ],
          [
            'boja',
            'Sivo',
          ],
          [
            'Miris',
            'karakteristika',
          ],
          [
            'Gustoća (na 20°C)',
            '0,66 g/cm 3 , djelatna tvar: 0,88 g/ml',
          ],
          [
            'zapaljivo',
            'ne odnosi se na aerosole',
          ],
          [
            'Sadržaj otapala, VOC (EU)	',
            '80%',
          ],
          [
            'temperatura samozapaljenja',
            'ne odnosi se na aerosole',
          ],
          [
            'Eksplozivna svojstva',
            'nije eksplozivan, moguće je stvaranje eksplozivne smjese para/zrak',
          ],
          [
            'Opasne reakcije',
            'Ne',
          ],
          [
            'topljivosti',
            'Nema informacija',
          ],
          [
            'Odlaganje (AVV)',
            '150104 (metalna ambalaža) 150110 (može sadržavati ostatke opasnih tvari)',
          ],
          [
            'prijevoz',
            'UN1950 (Aerosoli)',
          ],
          [
            'stabilnost skladištenja',
            'najmanje 2 godine',
          ],
        ],
        image: spray,
      },
      {
        id: 24,
        name: 'CICO® HWS T',
        link: '',
        short: 'craftsman sprej transparentan, sprej za podmazivanje i konzerviranje | čist',
        bulletpoints: [
          'optimalno mazivo u području kućanstva (pogodno za prozore, vrata, brave, šivaće strojeve, ležajeve, šarke itd.)',
          'štiti i čuva',
          'čisti i otapa',
          'Čist, do dubine pora suh klizni i zaštitni film',
          'odbija prašinu i prljavštinu',
          'istiskivanje vlage',
          'ekonomičan u potrošnji',
        ],
        description: [
          'CICO® HWS T – Handyman Spray transparent je sintetičko sredstvo za podmazivanje i konzervans u spreju. Čak i tanak, nevidljiv i suh klizni i zaštitni film prodire u najfinije pore, uvlači se ispod i istiskuje vlagu, čisti i olabavljuje smolaste dijelove i pruža dugotrajnu zaštitu od korozije. Sprej je posebno popularan u sektoru kućanstva: vrata i prozori više ne škripe i ne lijepe se, zaglavljene brave se otpuštaju. Osim toga, precizni mehanički elementi su podmazani i očuvani čisto i vrlo učinkovito. Zahvaljujući visokom učinku penetracije, mogu se tretirati i teško dostupni dijelovi, poput šarki, cilindara brava, lanaca itd.', 
          'CICO® HWS T – Handyman Spray transparent se isporučuje u sprej limenkama od 400 ml. Za optimalnu primjenu koristi se dodatni nastavak cijevi za raspršivanje.',
          'Sprej je pod pritiskom. Ne smije doći u ruke djece i mora biti zaštićen od zagrijavanja i oštećenja. Nemojte prskati na otvoreni plamen ili bilo koji užareni predmet. Koristite samo na otvorenom ili u dobro prozračenom prostoru. Izbjegavajte udisanje aerosola i zaštitite oči. Odložiti samo kada je potpuno prazan.',
        ],
        table: [
          [
            'NAZIV',
            'CICO® HWS T',
          ],
          [
            'oblik',
            'Aerosol, djelatna tvar: tekućina',
          ],
          [
            'boja',
            'žuta boja',
          ],
          [
            'Miris',
            'karakteristika',
          ],
          [
            'temperatura paljenja',
            'ne odnosi se na aerosole',
          ],
          [
            'Sadržaj otapala, VOC (EU)',
            '91%',
          ],
          [
            'temperatura samozapaljenja',
            'ne odnosi se na aerosole',
          ],
          [
            'Eksplozivna svojstva',
            'nije eksplozivan, moguće je stvaranje eksplozivne smjese para/zrak',
          ],
          [
            'Opasne reakcije',
            'Ne',
          ],
          [
            'topljivost u vodi',
            'nije rješivo',
          ],
          [
            'Odlaganje (AVV)',
            '150104 (metalna ambalaža) 150110 (može sadržavati ostatke opasnih tvari)',
          ],
          [
            'prijevoz',
            'UN1950 (Aerosoli)',
          ],
          [
            'stabilnost skladištenja',
            'najmanje 2 godine',
          ],
        ],
        image: spray,
      },
      {
        id: 25,
        name: 'CICO® visokoučinkoviti sprej za lanac',
        link: '',
        short: 'sintetičko mazivo i sprej za zaštitu',
        bulletpoints: [
          'dobro prianjajuća suha klizna folija, prozirna',
          'štiti i čuva metale i legure',
          'čisti i otpušta oksidirane i smolaste dijelove',
          'smanjuje gubitke trenjem i koroziju',
          'odbija prašinu i prljavštinu',
          'istiskivanje vlage',
          'radi u temperaturnom rasponu od –40°C do +270°C',
          'bez mineralnih ulja',
        ],
        description: [
          'CICO® visokoučinkoviti sprej za lanac stvara tanak, nevidljiv i suh klizni i zaštitni film. Prodire u pore metalnih površina i najfinije pukotine te čisti, otapa i omogućuje čisto podmazivanje otporno na visoki tlak. Štiti površinu, uvlači se i istiskuje vlagu. Trošenje materijala je znatno smanjeno, a otpor trenja smanjen. CICO® visokoučinkoviti sprej za lanacnudi visoku razinu zaštite od korozije i istovremeno visok učinak čišćenja i otapala. Sprej omogućuje čisto podmazivanje i očuvanje pokretnih dijelova u svim područjima mehanike (od jednostavnih kliznih ležajeva preko lanaca do uređaja za preciznu i finu mehaniku). Zahvaljujući visokom učinku penetracije, teško dostupni dijelovi također se mogu optimalno tretirati.', 
          'CICO® visokoučinkoviti sprej za lanac isporučuje se u spreju od 400 ml. Za optimalnu primjenu koristi se dodatni nastavak cijevi za raspršivanje.',
          'Sprej je pod pritiskom. Ne smije doći u ruke djece i mora biti zaštićen od zagrijavanja i oštećenja. Nemojte prskati na otvoreni plamen ili bilo koji užareni predmet. Koristite samo na otvorenom ili u dobro prozračenom prostoru. Izbjegavajte udisanje aerosola i zaštitite oči. Odložiti samo kada je potpuno prazan.',
        ],
        table: [
          [
            'NAZIV',
            'Aerosol, djelatna tvar: tekućina',
          ],
          [
            'oblik',
            'Aerosol, djelatna tvar: tekućina',
          ],
          [
            'boja',
            'žuta boja',
          ],
          [
            'Miris',
            'karakteristika',
          ],
          [
            'temperatura paljenja',
            'ne odnosi se na aerosole',
          ],
          [
            'Sadržaj otapala, VOC (EU)',
            '91%',
          ],
          [
            'temperatura samozapaljenja',
            'ne odnosi se na aerosole',
          ],
          [
            'Eksplozivna svojstva',
            'nije eksplozivan, moguće je stvaranje eksplozivne smjese para/zrak',
          ],
          [
            'Opasne reakcije',
            'Ne',
          ],
          [
            'topljivost u vodi',
            'nije rješivo',
          ],
          [
            'Odlaganje (AVV)',
            '150104 (metalna ambalaža) 150110 (može sadržavati ostatke opasnih tvari)',
          ],
          [
            'prijevoz',
            'UN1950 (Aerosoli)',
          ],
          [
            'stabilnost skladištenja',
            'najmanje 2 godine',
          ],
        ],
        image: spray,
      },
      {
        id: 26,
        name: 'CICO® Electrospray',
        link: '',
        short: 'Sintetički sprej za zaštitu i konzerviranje kontakta',
        bulletpoints: [
          'Optimalna zaštita kontakta za elektrotehniku ​​i elektroniku',
          'poboljšana vodljivost',
          'nema oksidacije',
          'izuzetno dobra antikorozivna zaštita',
          'vrlo dobra penetracija',
          'istiskivanje vlage',
          'odbija prašinu i prljavštinu',
          'čisti i otapa oksidirane i smolaste elemente',
        ],
        description: [
          'CICO® Electrospray stvara tanak, nevidljiv i suh zaštitni film s visokim učinkom otapala. Prodire u pore metalnih površina, uvlači se ispod i istiskuje vlagu, nudi visoku moć čišćenja i otapala te značajno poboljšava vodljivost. Visoka zaštita od korozije ima dugotrajan učinak.', 
          'CICO® Electrospray je posebno razvijen za sektor elektrotehnike/elektronike. Zahvaljujući visokom učinku prodiranja, elementi koji su teško dostupni također se mogu optimalno tretirati.',
          'Za ciljanu upotrebu, CICO® Electrospray se isporučuje s dodatnim produžetkom cijevi za raspršivanje.',
          'CICO® Electrospray nudi se kao sprej (400 ml).',
          'Sprej je pod pritiskom. Ne smije doći u ruke djece i mora biti zaštićen od zagrijavanja i oštećenja. Nemojte prskati na otvoreni plamen ili bilo koji užareni predmet. Koristite samo na otvorenom ili u dobro prozračenom prostoru. Izbjegavajte udisanje aerosola i zaštitite oči. Odložiti samo kada je potpuno prazan.',
        ],
        table: [
          [
            'NAZIV',
            'CICO® elektrosprej',
          ],
          [
            'oblik',
            'Aerosol, djelatna tvar: tekućina',
          ],
          [
            'boja',
            'bezbojan',
          ],
          [
            'Miris',
            'karakteristika',
          ],
          [
            'temperatura paljenja',
            'ne odnosi se na aerosole',
          ],
          [
            'Sadržaj otapala, VOC (EU)',
            '91%',
          ],
          [
            'temperatura samozapaljenja',
            'ne odnosi se na aerosole',
          ],
          [
            'Eksplozivna svojstva',
            'nije eksplozivan, moguće je stvaranje eksplozivne smjese para/zrak',
          ],
          [
            'Opasne reakcije',
            'Ne',
          ],
          [
            'topljivost u vodi',
            'nije rješivo',
          ],
          [
            'Odlaganje (AVV)',
            '150104 (metalna ambalaža) 150110 (može sadržavati ostatke opasnih tvari)',
          ],
          [
            'prijevoz',
            'UN1950 (Aerosoli)',
          ],
          [
            'stabilnost skladištenja',
            'najmanje 2 godine',
          ],
        ],
        image: spray,
      },
    ],
    productImage: p3
  }
];

export default products;