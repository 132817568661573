import React from 'react';
import {Link} from "gatsby"
import Button from '../components/Button';

const CustomerCard = ({ product }) => {
  return(
  <div className="mb-8">
    <img
        className="w-full mr-4"
        src={product?.productImage}
      />
    <p className="text-md font-light mt-4">{product?.short}</p>
    <p className="text-xl font-bold mt-2">{product?.title}</p>
    <Link to="/proizvodi" state={{id: product.id}}>
      <Button size="md" className='mt-4 px-20 mx-auto' border>Više</Button>
    </Link>
  </div>
);
  }

export default CustomerCard;
